// src/pages/RoleAssignmentPage.tsx

import React, { useState } from 'react';
import { Form, Button, Spin } from 'antd';
import RoleSelect from './RBAC/RoleSelect';
import MenuRenderer from './RBAC/MenuRenderer';
import useRoleEffects from './RBAC/hooks/useRoleEffects';
import useRoleHandlers from './RBAC/hooks/useRoleHandlers';

const RoleAssignmentPage: React.FC = () => {
  const [selectedRole, setSelectedRole] = useState<string | null>(null);
  const [checkedActions, setCheckedActions] = useState<{ [subMenuId: number]: { [action: string]: boolean } }>({});
  const [initialCheckedActions, setInitialCheckedActions] = useState<{ [subMenuId: number]: { [action: string]: boolean } }>({});
  const [loading, setLoading] = useState<boolean>(false);
  
  const { roles, menuData, fetchMenuData } = useRoleEffects(checkedActions, setCheckedActions, setInitialCheckedActions, setLoading);
  const { handleCheckboxChange, handleSubmit } = useRoleHandlers({selectedRole, menuData, checkedActions, initialCheckedActions, setCheckedActions, setLoading, fetchMenuData});
  
  const handleRoleChange = (roleId: string) => {
    setSelectedRole(roleId);
    fetchMenuData(roleId);
  };

  return (
    <div style={{ padding: 20 }}>
      <Form>
        <RoleSelect
          roles={roles}
          selectedRole={selectedRole}
          onRoleChange={handleRoleChange}
        />
      </Form>

      <Spin spinning={loading} size="large">
        {menuData.length > 0 && (
          <MenuRenderer
            menuData={menuData}
            checkedActions={checkedActions}
            onCheckboxChange={handleCheckboxChange}
          />
        )}

        <Button
          type="primary"
          onClick={handleSubmit}
          disabled={!selectedRole || loading}
          style={{ marginTop: 20}}
        >
          Submit
        </Button>
      </Spin>
    </div>
  );
};

export default RoleAssignmentPage;
